<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />

    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      color="red"
    />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

    <dashboard-core-view />
  </v-app>
</template>

<script>
export default {
  name: 'DashboardIndex',

  components: {
    DashboardCoreAppBar: () => import('./components/core/AppBar'),
    DashboardCoreDrawer: () => import('./components/core/Drawer'),
    DashboardCoreView: () => import('./components/core/View')
  },

  data: () => ({
    expandOnHover: false,
    image: null
  }),

  computed: {
    loading () {
      return false // this.$store.state.loading
    }
  }
}
</script>
